const USER = {
  JOIN: "join",
  JOINED: "joined",
  LEAVE: "leave",
  MESSAGE: "message",
  FOCUS_ON: "focus-on",
  FOCUS_OFF: "focus-off",
  QUESTON: "question",
};

module.exports = USER;
